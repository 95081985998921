// colors

$main-text: #7f8c97; // main text
$link: #acb7c0; // anchor tags
$background: #e9f0f5; // body background color

// colors

$color-1: #383838; // Mine Shaft
$color-2: #7b9d6f; // Highland
$color-3: #ffffff; // White
$color-4: #f8f8f8; // Alabaster
$color-5: #f0ca45; // yellow
$color-6: #75ce66; // Green